import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Grid from '@material-ui/core/Grid';
import 'react-vertical-timeline-component/style.min.css';
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
    backgroundColor: '#cdcdcd',
    padding: '10vh 0px 10vh 0px',
  },
  header: {
    color: 'rgb(38, 85, 98)',
    fontSize: '30pt',
    padding: '1vw',
    fontFamily: 'Raleway',
    marginBottom: '3vw',
    fontWeight: '200',
    [theme.breakpoints.down('md')]: {
      fontSize: '20pt',
    },
  },
}));

const Experience = () => {
  const classes = useStyles();

  return (

    <Grid
      id="experience"
      container
      xs={12} spacing={0}
      className={classes.root}
      direction="column"
      alignItems="center"
      justify="center">
      <Grid item className={classes.header} xs={12} lg={8}>
        Experience and Education
      </Grid>

      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="October 2022 - present"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>Cloud Engineer</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>Provectus Algae</h4>
          <p style={{ width: '100%', fontFamily: 'Raleway', }}>
            Building out serverless architecture integrating with on-premise biotechnology and machine learning infrastructure
          </p>
        </VerticalTimelineElement>        
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Nov 2001 - Nov 2005"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>Technical Lead</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>FuPay</h4>
          <p style={{ width: '100%', fontFamily: 'Raleway', }}>
            Developing and architecting serverless applications for the FuPay BNPL platform
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Nov 2001 - Nov 2005"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>Team Lead</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>Swyftx</h4>
          <p style={{ width: '100%', fontFamily: 'Raleway', }}>
            Maintaining and improving the Node.js backend and AngularJS frontend for the Swyftx cryptocurrency trading platform
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Nov 2015 - April 2020"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>Full Stack Engineer and Outsourced CTO</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>Belts and Braces Software</h4>
          <p style={{ width: '100%', fontFamily: 'Raleway', }}>
            Web application and mobile development in the retail and transport industries. CTO for national branch of large multi-national
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2019"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>Building on AWS</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>Amazon Web Services</h4>

        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2018"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>Blockchain Specialization</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>Univesity of Buffalo</h4>

        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2018"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>React Foundation Course</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>Node University</h4>

        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Dec 2005 - Oct 2015"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>Engineering Manager</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>Paramita Software</h4>
          <p>
            Web application development in the retail, government and transport industries, using Oracle Apex-PL/SQL and Java
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2005"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>Honours BSc (Computer Science)</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>University of South Africa</h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Nov 2001 - Nov 2005"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>Junior Manager</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>iFactory Solutions cc</h4>
          <p>
            Web services utilising a thin client, J2EE middle tier and Oracle database system for both government and private sector customers
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2001"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title" style={{ fontWeight: 'normal', marginBottom: 10 }}>BSc (Information Systems)</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ fontWeight: 'normal' }}>Rhodes University</h4>

        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={<StarIcon />}
        />
      </VerticalTimeline>

    </Grid>

  )
}

export default Experience;