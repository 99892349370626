import React from "react"
import Fade from 'react-reveal/Fade';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import FaceIcon from '@material-ui/icons/Face';
import WorkIcon from '@material-ui/icons/Work';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { loadCSS } from 'fg-loadcss';

import Header from "../components/header"
import AboutMe from "../components/aboutme"
import Experience from '../components/experience';
import Skills from '../components/skills';
import SEO from "../components/seo"
// clients

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    border: '1px solid #efefef'
  },
  links: {
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  link: {
    color: '#8e8e8e',
  }
});

const IndexPage = () => {
  const classes = useStyles();

  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.13.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
  }, []);
  
  return (
    <div>
      <SEO title="Home" />
      <Header />
      <Fade right>
        <AboutMe />
      </Fade>
      <Fade left>
        <Experience />
      </Fade>
      <Fade right>
        <Skills />
      </Fade>
      {/*<Fade left>
        <Projects />
      </Fade>*/}
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <BottomNavigation showLabels> 
            <BottomNavigationAction label="Home" value="recents" icon={<HomeIcon />} onClick={() => scrollTo('#home')} />
            <BottomNavigationAction label="About Me" value="favorites" icon={<FaceIcon />} onClick={() => scrollTo('#about-me')} />
            <BottomNavigationAction label="Experience" value="nearby" icon={<WorkIcon />} onClick={() => scrollTo('#experience')} />
            <BottomNavigationAction label="Skills" value="folder" icon={<SettingsIcon />} onClick={() => scrollTo('#skills')} />
          </BottomNavigation>
        </Grid>
      </Grid>
      <Grid container className={classes.links}>
        <IconButton href="https://github.com/semiautomatix" target="_blank">
          <Icon className={`fab fa-github ${classes.link}`} />
        </IconButton>
        <IconButton href="https://www.linkedin.com/in/tgaul/?originalSubdomain=au" target="_blank">
          <Icon className={`fab fa-linkedin ${classes.link}`} />
        </IconButton>
      </Grid>
    </div>
  );
}

export default IndexPage
