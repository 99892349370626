import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import 'react-vertical-timeline-component/style.min.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import JavaScriptImg from '../assets/javascript-2.png';
import JavaImg from '../assets/java-2.png';
import PythonImg from '../assets/python-2.png';
import SkillsImg from '../assets/skills-3.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
    backgroundColor: '#ffffff',
    padding: '10vh 0px 10vh 0px',
  },
  header: {
    color: 'rgb(38, 85, 98)',
    fontSize: '30pt',
    padding: '1vw',
    fontFamily: 'Raleway',
    marginBottom: '3vw',
    fontWeight: '200',
    [theme.breakpoints.down('md')]: {
      fontSize: '20pt',
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  card: {
    textAlign: 'center',
    flexGrow: 1,
    padding: '10px',
    height: '400px',
    minHeight: '400px'
  },
  skillImg: {
    width: '8vw',
    fontSize: 0,
    borderRadius: '50%',
  },
}));

const Skills = () => {
  const classes = useStyles();

  return (
    <Grid 
      id="skills"
      container xs={12} 
      spacing={0}
      className={classes.root}
      direction="column"
      alignItems="center"
      justify="center">
      <Grid item className={classes.header} xs={12} lg={8}>
        Skills
      </Grid>
      <Grid container xs={12} lg={8} spacing={0} direction='row'>
        <Grid item className={classes.header} xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <img src={JavaScriptImg} alt="Type Script" className={classes.skillImg} />
              <Typography variant="h5" className={classes.pos}>
                JavaScript
              </Typography>
              <Typography variant="body2" component="p">
                React
              </Typography>
              <Typography variant="body2" component="p">
                React Native
              </Typography>
              <Typography variant="body2" component="p">
                Type Script
              </Typography>
              <Typography variant="body2" component="p">
                Node.js
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item className={classes.header} xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <img src={PythonImg} alt="Type Script" className={classes.skillImg} />
              <Typography variant="h5" className={classes.pos}>
                Python
              </Typography>
              <Typography variant="body2" component="p">
                Django
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item className={classes.header} xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <img src={JavaImg} alt="Type Script" className={classes.skillImg} style={{ border: '1px solid #efefef' }} />
              <Typography variant="h5" className={classes.pos}>
                Java
              </Typography>
              <Typography variant="body2" component="p">
                Android
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item className={classes.header} xs={12} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <img src={SkillsImg} alt="Type Script" className={classes.skillImg} />
              <Typography variant="h5" className={classes.pos}>
                Other
              </Typography>
              <Typography variant="body2" component="p">
                AWS
              </Typography>
              <Typography variant="body2" component="p">
                Oracle PL/SQL
              </Typography>
              <Typography variant="body2" component="p">
                Oracle Apex
              </Typography>
              <Typography variant="body2" component="p">
                GraphQL
              </Typography>
              <Typography variant="body2" component="p">
                NoSQL
              </Typography>
              <Typography variant="body2" component="p">
                Docker
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>

  )
}

export default Skills;