import React from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Mugshot from '../assets/badger_quest.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '10vh 0px 10vh 0px',
    minHeight: '100vh',
    backgroundColor: 'rgba(38, 85, 98, 0.85)'
  },
  header: {
    color: '#ffffff',
    fontSize: '30pt',
    padding: '1vw',
    fontFamily: 'Raleway',
    marginBottom: '3vw',
    fontWeight: '100',
    [theme.breakpoints.down('md')]: {
      fontSize: '20pt',
    },
  },
  content: {
    color: '#ffffff',
    fontSize: '12pt',
    padding: '1vw',
    fontFamily: 'Raleway',
    textAlign: 'justify',
  },
  button: {
    marginWidth: '0.1vw',
    fontFamily: 'Raleway',
    width: '100%'
  },
  mugshot: {
    width: '12vw',
    borderRadius: '50%',
    borderColor: 'white',
    borderWidth: 2,
    borderStyle: 'solid',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    [theme.breakpoints.up('md')]: {
      marginRight: 10
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 10
    },
  },
  wrapper: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0 5vw 0 5vw'
    },
  }
}));

const AboutMe = () => {
  const classes = useStyles();

  return (
    <div
      id="about-me"
      className={classes.root}
    >
      <div className={classes.wrapper}>
        <Grid container xs={12} lg={8}>
          <Grid item className={classes.header} xs={12}>
            Overview of professional career
          </Grid>
          <Grid container item xs={12}>
            <Grid item className={classes.position} xs={12} lg={6}>
              I am a highly experienced software engineer having worked in information technology and software
              development for almost 20 years in various technologies including Node.js, React, React-Native,
              Python and AWS, as well as Oracle-Java, Oracle Apex-PL/SQL.<br />
              <br />
              Currently I am heading up Cloud Engineering and DevOps at Provectus Algae, a biomanufacturing platform for large scale designer bioproducts.<br />
              <br />
              I started Belts and Braces in 2015, at the time predominantly consulting to clients in the
              retail space and providing their point of sale, logistics and warehousing systems. Over the years, as I was able to automate my clients' systems and they were less reliant on me, I was able to take on more specialist projects in custom development in different industries and markets.
            </Grid>
            <Grid item className={classes.location} xs={12} lg={6}>
              <img src={Mugshot} alt="Tim Gaul" className={classes.mugshot} />
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.content}>
            In 2017 I started a second business with two other shareholders, which is focused on home automation, one of the first of its kind in South Africa. With my background I was able to provide clients with home automation products specifically tailored to their individual needs. In order to run both businesses, I have taken advantage of new innovations in product delivery such as outsourced warehousing.
            Running my own businesses meant that I was able to take the opportunity to learn and study further when the occasion arose.
          </Grid>
          <Grid container item xs={12} className={classes.content} direction='row'>
            {/* <Grid item xs={12} md='auto' className={classes.buttonContainer}>
              <Button variant="contained" color="primary" className={classes.button} href="https://s3-ap-southeast-2.amazonaws.com/gaul.dev/downloads/Timothy+Gaul.Resume.pdf" target="_blank">Résumé</Button>
            </Grid> */}
            <Grid item xs={12} md='auto'>
              <Button variant="contained" color="secondary" className={classes.button} href="mailto:tim@gaul.dev">Contact Me</Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AboutMe;